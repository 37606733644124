// import http from "@/utils/request";
import dmshttp from "@/utils/dmsrequest";

export function agentReturnOrderListNew(data) {
    return dmshttp({
        url: 'agentReturnOrderListNew',
        data
    })
}

// 导出
export function exportOrder(data) {
    return dmshttp({
        url: 'exportAgentReturnOrderListNew'+ '&type=1',
        data,
        responseType: 'blob'
    })
}

// 分銷商按单退货
export function retrunOrderQuest(data) {
    return dmshttp({
        url: 'purchaseCreationReturn',
        data
    })
}

export function salesReturn(data) {
    return dmshttp({
        url: 'cancelReturn',
        data
    })
}
// /wechatEntrance/entrance.do?method=getStockTransProductDetailList
